import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import CashierIcon from "../assets/svg/CashierIcon";
import logo from "../assets/svg/logo_new.png";
import BotHeadIcon1 from "../assets/images/games-icon/play3d.png";
import BotHeadIcon2 from "../assets/images/games-icon/cricket-icon.png";
import BotHeadIcon3 from "../assets/images/games-icon/football.png";
import BotHeadIcon4 from "../assets/images/games-icon/plane.png";
import BotHeadIcon5 from "../assets/images/games-icon/dragon.png";
import BotHeadIcon6 from "../assets/images/games-icon/cards.png";
import BotHeadIcon7 from "../assets/images/games-icon/fortune-wheel.png";
import { useSelector } from "react-redux";

import SportsIconNew from "../assets/images/games-icon/headicon/sports.svg";
import CashierIconNew from "../assets/images/games-icon/headicon/cashier.svg";
import EzugiIconNew from "../assets/images/games-icon/headicon/roulette.svg";
import EvolutionIconNew from "../assets/images/games-icon/headicon/table-games-new.svg";
import KingmakerIconImg from "../assets/images/games-icon/headicon/king.png";
import HomeIConNew from "../assets/images/games-icon/headicon/house.png";
import casinoround from "../assets/images/games-icon/headicon/spin_bg.png";
import casinogame from "../assets/images/games-icon/headicon/turntable.png";
import casinopoint from "../assets/images/games-icon/headicon/pointer.png";

import WhatsappIconNew from "../assets/images/social/whatsapp.png";
import TelegramIconNew from "../assets/images/social/telegram.png";

import MenuIcon from "../assets/images/icons/menu1.png";
import CasinoIcon from "../assets/images/icons/casino-white.png";
import SportsIcon1 from "../assets/images/icons/sports-icon.png";
import TextIcon from "../assets/images/icons/text-size.png";
import WhatsappIcon1 from "../assets/images/icons/whatsapp.png";
import BonusIcon from "../assets/images/icons/bonusicon.png";
import LogInIcon from "../assets/images/icons/login-white.png";
import SupportIcon from "../assets/images/icons/Support-white.png";

import SearchIcon from "../assets/images/icons/find.png";
import GamesSearchModal from "../views/components/GamesSearchModal";
import { getNotifications } from "../redux/app/actions";
import { AiOutlineClose } from "react-icons/ai";

const Header = () => {
  //test
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [fontConfig, setFontConfig] = useState({
    counter: 0,
    fontSize: 14,
    type: "inc",
  });
  const [websiteNotifications, setWebsiteNotifications] = useState([]);

  const [showNotifications, setShowNotifications] = useState(true);
  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };
  useEffect(() => {
    loadNotifications();
  }, []);
  const handleFontConfig = () => {
    if (fontConfig.counter <= 2 && fontConfig.type == "inc") {
      if (fontConfig.counter === 2) {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter - 1,
          fontSize: p.fontSize - 2,
          type: "dec",
        }));
      } else {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter + 1,
          fontSize: p.fontSize + 2,
        }));
      }
    } else if (fontConfig.counter >= 0 && fontConfig.type == "dec") {
      if (fontConfig.counter === 0) {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter + 1,
          fontSize: p.fontSize + 2,
          type: "inc",
        }));
      } else {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter - 1,
          fontSize: p.fontSize - 2,
        }));
      }
    }
  };
  return (
    <>
      {" "}
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }}>{notf?.content}</label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}
      <header className="header beforeheader">
        <div className="top_head">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="logo">
                    <a href="/">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>

                  <div className="middle-part d-none">
                    <Navbar expand="lg">
                      <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Nav className="me-auto">
                            <Nav.Link href="/">
                              {/* <HomeIcon /> */}
                              <img src={HomeIConNew} />
                              Home
                            </Nav.Link>
                            <Nav.Link href="sports/cricket" className="">
                              <div>
                                <img src={SportsIconNew} />
                              </div>
                              Exchange
                            </Nav.Link>
                            {/* <Nav.Link href="/sports">
                          <img src={Exchange} alt="Exchange Icon" width={20} />{" "}
                          Exchange
                        </Nav.Link> */}
                            <Nav.Link href="/sign-in">
                              {/* <GamesIcon /> */}
                              <div className="animate-img">
                                <div className="enter-animate active">
                                  <div className="spin-anim">
                                    <img
                                      src={casinoround}
                                      className="img-spinbg"
                                    />
                                    <img
                                      src={casinogame}
                                      className="img-turntable"
                                    />
                                    <img
                                      src={casinopoint}
                                      className="img-pointer"
                                    />
                                  </div>
                                </div>
                              </div>
                              Casino
                            </Nav.Link>
                            <Nav.Link href="/sign-in">
                              {/* <CashierIcon />  */}
                              <img src={EzugiIconNew} />
                              Ezugi
                            </Nav.Link>
                            <Nav.Link href="/sign-in">
                              {/* <CashierIcon />  */}
                              <img src={EvolutionIconNew} />
                              Evolution
                            </Nav.Link>
                            <Nav.Link href="/sign-in">
                              {/* <CashierIcon />  */}
                              <img src={CashierIconNew} />
                              Cashier
                            </Nav.Link>
                          </Nav>
                        </Navbar.Collapse>
                      </Container>
                    </Navbar>
                  </div>

                  <div className="headerRight">
                    <div className="me-2 d-none d-md-block">
                      <GamesSearchModal />
                    </div>

                    {/* <div className="headicons" onClick={handleFontConfig}>
                    <img src={TextIcon} className="texticon" />
                  </div> */}

                    <a href="/sign-in" className="headicons">
                      <img src={SportsIcon1} /> Sports
                    </a>
                    <a href="/casino/evolution" className="headicons">
                      <img src={EvolutionIconNew} /> Evolution
                    </a>
                    <a href="/kingmaker" className="headicons">
                      <img src={KingmakerIconImg} /> Kingmaker
                    </a>
                    <a
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                          : "#"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      className="headicons support"
                    >
                      <img src={WhatsappIcon1} /> Support
                    </a>
                    <a href="/sign-in" className="headicons">
                      <img src={BonusIcon} /> Bonus
                    </a>
                    <ul className="social d-none">
                      <li>
                        <a
                          href={
                            appDetails?.TELEGRAM != ""
                              ? appDetails?.TELEGRAM
                              : "#"
                          }
                          target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                        >
                          {/* <TelegramIcon /> */}
                          <img src={TelegramIconNew} />
                        </a>
                      </li>
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "#"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          {/* <WhatsappIcon /> */}
                          <img src={WhatsappIconNew} />
                        </a>
                      </li>
                    </ul>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/sign-in");
                      }}
                      className="button-primary btn_signin"
                    >
                      Log In
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/mobile-number");
                      }}
                      className="button-primary btn_signup btn-secondary ms-2"
                    >
                      Register
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="col-12 col-sm-7 mx-auto mt-1 d-md-none">
                  <GamesSearchModal />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <div className="bottom_head">
        <Container fluid>
          <Row>
            <Col xs={12} className="px-0">
              <ul style={{ fontSize: `${fontConfig.fontSize}px` }}>
                <li>
                  <a href="/sign-in">
                    <img
                      src={BotHeadIcon1}
                      alt="bottom head link icon"
                      className="inplay"
                    />
                    <span>Ezugi</span>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img
                      src={BotHeadIcon1}
                      alt="bottom head link icon"
                      className="inplay"
                    />
                    <span>Slots</span>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img
                      src={BotHeadIcon1}
                      alt="bottom head link icon"
                      className="inplay"
                    />
                    <span>Evolution</span>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={BotHeadIcon2} alt="bottom head link icon" />
                    <span>Aviator</span>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={BotHeadIcon3} alt="bottom head link icon" />
                    <span>Spribe</span>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={BotHeadIcon4} alt="bottom head link icon" />
                    <span>Vivo</span>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={BotHeadIcon4} alt="bottom head link icon" />
                    <span>XPG</span>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div> */}

        {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                 Login with OTP
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
      </header>
    </>
  );
};

export default Header;
