import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Modal, Nav, Navbar, Form } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import MenuIcon from "../assets/svg/MenuIcon";
import MenuIcon1 from "../assets/images/icons/menuicon.png";
import ProfileIcon from "../assets/svg/ProfileIcon";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WalletIcon from "../assets/svg/WalletIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import BetIcon from "../assets/svg/BetIcon";
import SettingIcon from "../assets/svg/SettingIcon";
import StatementIcon from "../assets/svg/StatementIcon";
import LogoutIcon from "../assets/svg/LogoutIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import logo from "../assets/svg/logo_new.png";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import numberWithCommas from "../utils/numbersWithComma";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import { IoWalletOutline } from "react-icons/io5";
import ExchangeFundsFormPopUp from "../views/components/ExchangeFundsFormPopUp";
import { CasinoProviders } from "../lib/data";
// import Index from "../views/pages/privatePages/exchangepopup";
import { getExposureAmount } from "../redux/auth/actions";
import { getBetMatches, getUserBet } from "../redux/sports/actions";

import HomeIcon1 from "../assets/images/games-icon/home-icon1.png";
import EzugiIcon from "../assets/images/games-icon/Ezugi.png";
import EvolutionIcon from "../assets/images/games-icon/Evolution.png";
import CashierIcon1 from "../assets/images/games-icon/cashier.png";

import SportsIconNew from "../assets/images/games-icon/headicon/sports.svg";
import CashierIconNew from "../assets/images/games-icon/headicon/cashier.svg";
import EzugiIconNew from "../assets/images/games-icon/headicon/roulette.svg";
import EvolutionIconNew from "../assets/images/games-icon/headicon/table-games-new.svg";
import KingmakerIconImg from "../assets/images/games-icon/headicon/king.png";
import HomeIConNew from "../assets/images/games-icon/headicon/house.png";
import casinoround from "../assets/images/games-icon/headicon/spin_bg.png";
import casinogame from "../assets/images/games-icon/headicon/turntable.png";
import casinopoint from "../assets/images/games-icon/headicon/pointer.png";
import Bonus from "../assets/images/icons/bonusicon.png";
import BotHeadIcon1 from "../assets/images/games-icon/play3d.png";
import BotHeadIcon2 from "../assets/images/games-icon/cricket-icon.png";
import BotHeadIcon3 from "../assets/images/games-icon/football.png";
import BotHeadIcon4 from "../assets/images/games-icon/plane.png";
import BotHeadIcon5 from "../assets/images/games-icon/dragon.png";
import BotHeadIcon6 from "../assets/images/games-icon/cards.png";
import BotHeadIcon7 from "../assets/images/games-icon/fortune-wheel.png";
import Referral from "../assets/images/refferal.png";
import Bonus1 from "../assets/images/icons/bonusicon.png";

import TextIcon from "../assets/images/icons/text-size.png";
import WhatsappIcon1 from "../assets/images/icons/whatsapp.png";
import BonusIcon from "../assets/images/icons/bonusicon.png";
import SportsIcon1 from "../assets/images/icons/sports-icon.png";
import SearchIcon from "../assets/images/icons/find.png";
import WalletIcon1 from "../assets/images/icons/wallet.png";
import BonusIcon1 from "../assets/images/icons/bonus.gif";
import AccountStatement1 from "../assets/images/icons/Account-statement.png";
import ReferralIcon1 from "../assets/images/icons/withdraw.png";
import MyBets1 from "../assets/images/icons/mybets.png";
import SignOut1 from "../assets/images/icons/logout.png";
import ProfileIcon1 from "../assets/images/icons/Profile.png";
import ProfileIconnew from "../assets/images/icons/user.png";
import { getNotifications, handleRightSideBar } from "../redux/app/actions";

import FetchingBal from "../assets/images/icons/fetch.gif";
import refferal from "../assets/images/icons/connections.png";
import { slotsData } from "../lib/data/slots.data";
import GamesSearchModal from "../views/components/GamesSearchModal";
import { AiOutlineClose } from "react-icons/ai";

const HeaderAfterLogin = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const { betMatches, userBets } = useSelector((state) => state.sports);
  const { showRightSideBar } = useSelector((state) => state.app);
  const [showBalance, setShowBalanceLoading] = useState(false);
  const [searchText, setSearchText] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);

  const [showNotifications, setShowNotifications] = useState(true);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    handleSideBarVisibility(false);
    setShow(false);
  };
  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showexchangeModal, setShowexchangeModal] = useState(false);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const [fontConfig, setFontConfig] = useState({
    counter: 0,
    fontSize: 12,
    type: "inc",
  });

  const [showToggle, setShowToggle] = useState(true);

  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);
  useEffect(() => {
    // dispatch(getExposureAmount());
    // dispatch(getBetMatches({ status: "pending" }));
    setShowToggle(window.screen.width > 992);
    let intervalID = setInterval(() => {
      setShowBalanceLoading((p) => !p);
    }, 5000);
    loadNotifications();
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  const [showLightModeToggle, setShowLightModeToggle] = useState(false);
  useEffect(() => {
    if (showLightModeToggle) {
      document.body.classList.add("light-mode");
    } else {
      document.body.classList.remove("light-mode");
    }
  }, [showLightModeToggle]);

  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  useEffect(() => {
    if (showLeftbarToggle) {
      document.body.classList.add("leftbat-opened");
    } else {
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);

  const handleSideBarVisibility = (value) => {
    dispatch(handleRightSideBar(value));
  };
  const handleFontConfig = () => {
    if (fontConfig.counter <= 2 && fontConfig.type == "inc") {
      if (fontConfig.counter === 2) {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter - 1,
          fontSize: p.fontSize - 2,
          type: "dec",
        }));
      } else {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter + 1,
          fontSize: p.fontSize + 2,
        }));
      }
    } else if (fontConfig.counter >= 0 && fontConfig.type == "dec") {
      if (fontConfig.counter === 0) {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter + 1,
          fontSize: p.fontSize + 2,
          type: "inc",
        }));
      } else {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter - 1,
          fontSize: p.fontSize - 2,
        }));
      }
    }
  };

  const renderBalance = () => {
    return (
      <>
        <div className="cashloader">
          <div className="balance-web">
            <ul>
              <li>
                {" "}
                Cash: <span>{numberWithCommas(wallet?.cash || 0)}</span>
              </li>
              <li>
                Bonus:
                <span> {numberWithCommas(wallet?.coins)}</span>
                {/* <span> {numberWithCommas(exposureAmount || 0)}</span> */}
              </li>
              {/* <li>
                  Sports: <span> {numberWithCommas(exposureAmount || 0)}</span>
                </li> */}
            </ul>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const offcanvas = document.querySelector(".offcanvas");

      if (showRightSideBar && offcanvas && !offcanvas.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showRightSideBar]);

  return (
    <>
      {" "}
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }}>{notf?.content}</label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}
      <header className="header aftrlgn">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="leftbar_toggle d-none ">
                    <Button
                      variant="primary"
                      onClick={() => setShowLeftbarToggle((s) => !s)}
                    >
                      {/* <MenuIcon /> */}
                      <img src={MenuIcon1} />
                    </Button>
                  </div>
                  <div className="logo">
                    <a href="/home">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>
                </div>

                <Navbar expand="xl" className="d-none">
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={() => setShowToggle((s) => !s)}
                  />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                      <Nav.Link href="/home" className="logoImg d-xl-none">
                        <img src={appDetails?.LOGO_URL} alt="Logo" />
                        {/* <img
                          src={LogoLightTheme}
                          alt="Logo Image"
                          className="dark-theme"
                        />
                        <img
                          src={LogoDarkTheme}
                          alt="Logo Image"
                          className="light-theme"
                        /> */}
                      </Nav.Link>
                      <Nav.Link href="/home">
                        {/* <HomeIcon />  */}
                        <img src={HomeIConNew} />
                        Home
                      </Nav.Link>
                      <Nav.Link href="/sportsbook/cricket" className="">
                        <div>
                          <img src={SportsIconNew} />
                        </div>
                        Exchange
                      </Nav.Link>
                      <Nav.Link href="/casino/spribe/aviator">
                        {/* <GamesIcon /> */}
                        <div className="animate-img">
                          <div className="enter-animate active">
                            <div className="spin-anim">
                              <img src={casinoround} className="img-spinbg" />
                              <img src={casinogame} className="img-turntable" />
                              <img src={casinopoint} className="img-pointer" />
                            </div>
                          </div>
                        </div>
                        Casino
                      </Nav.Link>

                      <Nav.Link href={CasinoProviders["ezugi"]?.href}>
                        {/* <GamesIcon />  */}
                        <img src={EzugiIconNew} />
                        Ezugi
                      </Nav.Link>
                      <Nav.Link href={CasinoProviders["evolution"]?.href}>
                        {/* <GamesIcon />  */}
                        <img src={EvolutionIconNew} />
                        Evolution
                      </Nav.Link>

                      <Nav.Link href="/cashier">
                        {/* <CashierIcon /> */}
                        <img src={CashierIconNew} />
                        Cashier
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>

                <div className="headerRight">
                  <div className="me-2 d-none d-md-block">
                    <GamesSearchModal />
                  </div>

                  {/* <div className="headicons" onClick={handleFontConfig}>
                  <img src={TextIcon} className="texticon" />
                </div> */}

                  <a href="/exchange/Inplay" className="headicons">
                    <img src={SportsIcon1} /> Sports
                  </a>
                  <a href="/casino/evolution" className="headicons">
                    <img src={EvolutionIconNew} /> Evolution
                  </a>
                  <a href="/kingmaker" className="headicons">
                    <img src={KingmakerIconImg} /> Kingmaker
                  </a>
                  <a
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                        : "#"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    className="headicons support"
                  >
                    <img src={WhatsappIcon1} /> Support
                  </a>
                  <a href="/bonus" className="headicons">
                    <img src={BonusIcon} />{" "}
                    <span className="hBonus">Bonus</span>
                  </a>
                  <a href="/wallet" className="headicons">
                    <img src={WalletIcon1} />{" "}
                    <span className="hWallet">Wallet</span>
                  </a>

                  {renderBalance()}
                  <Dropdown align="end" className="me-2 d-none">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="tel:{user?.mobileno}">
                        <b>{user?.mobileno}</b>
                      </Dropdown.Item>
                      <Dropdown.Item href="#">
                        <h6>Balance</h6>
                        <span>INR {numberWithCommas(wallet?.cash || 0)}</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => setShowBetModal(true)}
                      >
                        <h6>Exposure</h6>
                        <span>INR {numberWithCommas(exposureAmount || 0)}</span>
                      </Dropdown.Item>
                      <Dropdown.Item href="#">
                        <h6>Bonus</h6>
                        <span>INR {numberWithCommas(wallet?.coins)}</span>
                      </Dropdown.Item>
                      <Dropdown.Item href="/gatewaylist" className="deposit">
                        Deposit
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <div className="funds">
                    <Dropdown
                      className="headerMainDropdown d-block d-md-none"
                      placement={"end"}
                    >
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="deposit"
                      >
                        Deposit
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="">
                          <div className="row">
                            <div className="col-6">
                              <div className="balance mb-1">Main Balance</div>
                            </div>
                            <div className="col-6">
                              <div className="balance-amount mb-1">
                                {numberWithCommas(wallet?.cash || 0)}
                              </div>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item href="">
                          <div className="row">
                            <div className="col-6">
                              <div className="balance ">Bonus</div>
                            </div>
                            <div className="col-6">
                              <div className="balance-amount">
                                {numberWithCommas(wallet?.coins) || 0}
                              </div>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item href="">
                          <div
                            className="col-12 "
                            onClick={() => {
                              navigate("/withdraw");
                            }}
                          >
                            <Button variant="  w-100" className="primary">
                              Withdraw
                            </Button>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item href="">
                          <div
                            className="col-12"
                            onClick={() => {
                              navigate("/gatewaylist");
                            }}
                          >
                            <Button variant="primary w-100">Deposit</Button>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <Button
                    variant="primary menu-btn "
                    className="d-none d-md-block"
                    onClick={() => handleSideBarVisibility(true)}
                  >
                    <ProfileIcon />
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-7 mx-auto mt-1 d-md-none">
              <GamesSearchModal />
            </div>
          </div>
        </div>

        <Offcanvas
          show={showRightSideBar}
          onHide={handleClose}
          placement={"end"}
          className="menuDropdown"
        >
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>
              <div className="d-flex">
                <div className="profile-img">
                  <img src={ProfileIconnew} />
                </div>
                <div className="user-info">
                  <span>{user?.mstruserid}</span>
                  <a href="#">{user?.mobileno}</a>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="row offcanvas-head">
              <div className="col-6">
                <div className="balance mb-1">Main Balance</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-1">
                  {numberWithCommas(wallet?.cash || 0)}
                </div>
              </div>
              <div className="col-6">
                <div className="balance mb-3">Bonus</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-3">
                  {numberWithCommas(wallet?.coins) || 0}
                </div>
              </div>
              <div
                className="col-6 "
                onClick={() => {
                  window.location.href = "/withdraw";
                }}
              >
                <Button variant="  w-100" className="btn-secondary">
                  Withdraw
                </Button>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  window.location.href = "/gatewaylist";
                }}
              >
                <Button variant="primary w-100">Deposit</Button>
              </div>
              {/* <div className="col-4">
              <Button variant="primary w-100" onClick={handleShowModal}>
                Exchange
              </Button>
              <ExchangeFundsFormPopUp
                showexchangeModal={showexchangeModal}
                handleCloseModal={handleCloseModal}
              />
            </div> */}
            </div>
            <ul className="menu-items">
              <li>
                <a href="/profile">
                  <img src={ProfileIcon1} />
                  {/* <ProfileIcon /> */}
                  <span>Profile</span>
                </a>
              </li>
              <li>
                <a href="/mybets">
                  {/* <BetIcon /> */}
                  <img src={MyBets1} />
                  <span>My Bets</span>
                </a>
              </li>
              <li>
                <a href="/referral">
                  {/* <BetIcon /> */}
                  <img src={refferal} />
                  <span>Referral</span>
                </a>
              </li>
              <li>
                <a href="/bonus">
                  {/* <img src={Bonus1} /> */}
                  <img src={BonusIcon1} />
                  <span>Bonus</span>
                </a>
              </li>
              {/*<li>
              <a href="/referral">
                <img src={Referral} />
                <img src={ReferralIcon1} />
                <span>Referral</span>
              </a>
            </li> */}
              <li>
                <a href="/cashier">
                  {/* <StatementIcon /> */}
                  <img src={AccountStatement1} />
                  <span>Account Statement</span>
                </a>
              </li>

              {/* <li>
              <a href="#">
                <SettingIcon />
                <span>Transfer Funds ( For Excahnge)</span>
              </a>
            </li> */}
              {/* <li>
              <a href="/settings">
                <SettingIcon />
                <span>Settings</span>
              </a>
            </li> */}
              <li
                onClick={() => {
                  dispatch(logoutUser());
                }}
              >
                <a href="#">
                  {/* <LogoutIcon /> */}
                  <img src={SignOut1} />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
            <ul className="social_links">
              <li>
                <a
                  href={appDetails.TELEGRAM != "" ? appDetails.TELEGRAM : "#"}
                  target={appDetails.TELEGRAM != "" ? "_blank" : ""}
                >
                  <TelegramIcon />
                </a>
              </li>
              {/* <li>
              <div
                onClick={() =>
                  window.open(https://wa.me/+917888846060, "_blank")
                }
              >
                <WhatsappIcon />
              </div>
            </li> */}
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <WhatsappIcon />
                </a>
              </li>
              <li className="theme-btn">
                <button
                  type="button"
                  onClick={() => setShowLightModeToggle((s) => !s)}
                >
                  <div className="theme-icon">
                    <img
                      src={MoonLight}
                      alt="Moon Icon"
                      className="dark_theme"
                    />
                    <img
                      src={SunLight}
                      alt="Sun Icon"
                      className="light_theme"
                    />
                  </div>
                </button>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          className="betslip_popup outer_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Exposure amount</th>
                      <th scope="col">Match name</th>
                      <th scope="col">Ref match ID</th>
                      <th scope="col">Total bets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betMatches &&
                      betMatches.length &&
                      betMatches.map((item, index) => {
                        return (
                          <tr
                            onClick={() => {
                              handleRowClick(item);
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{item?.exposureAmount}</td>
                            <td>{item?.matchName}</td>
                            <td>{item?.refMatchId}</td>
                            <td>{item?.totalBets}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="betslip_popup inner_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showUserBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Odds</th>
                      <th scope="col">Stack</th>
                      <th scope="col">Selection type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Create date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userBets &&
                      userBets.length &&
                      userBets.map((item, index) => {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.odds}</td>
                            <td>{item?.stack}</td>
                            <td>{item?.selectionType}</td>
                            <td>{item?.status}</td>
                            <td>{item?.createdAt}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        {/* <div className="bottom_head">
        <Container fluid>
          <Row>
            <Col xs={12} className="px-0">
              <ul style={{ fontSize: `${fontConfig.fontSize}px` }}>
                <li>
                  <a href="/casino/ezugi">
                    <img
                      src={BotHeadIcon1}
                      alt="bottom head link icon"
                      className="inplay"
                    />
                    <span>Ezugi</span>
                  </a>
                </li>
                <li>
                  <a href="/Slots">
                    <img src={BotHeadIcon2} alt="bottom head link icon" />
                    <span>Slots</span>
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution">
                    <img src={BotHeadIcon3} alt="bottom head link icon" />
                    <span>Evolution</span>
                  </a>
                </li>
                <li>
                  <a href="/casino/spribe/aviator">
                    <img src={BotHeadIcon4} alt="bottom head link icon" />
                    <span>Aviator</span>
                  </a>
                </li>
                <li>
                  <a href="/Spribe">
                    <img src={BotHeadIcon5} alt="bottom head link icon" />
                    <span>Spribe</span>
                  </a>
                </li>
                <li>
                  <a href="/casino/vivo">
                    <img src={BotHeadIcon6} alt="bottom head link icon" />
                    <span>Vivo</span>
                  </a>
                </li>
                <li>
                  <a href="/casino/xpg">
                    <img src={BotHeadIcon6} alt="bottom head link icon" />
                    <span>XPG</span>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div> */}
      </header>
    </>
  );
};

export default HeaderAfterLogin;
